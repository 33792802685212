import { VariantProps } from 'class-variance-authority';
import { ComboBoxProps, ComboBox, ListBoxProps, Input, Group } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { FieldDescription, FieldError, FieldLabel, FieldProps } from '../../atoms/field/field';
import Icon from '../../atoms/icon/icon';
import { ListBox } from '../../atoms/list-box/list-box';
import { Popover } from '../../atoms/popover/popover';
import { Pressable } from '../../atoms/pressable/pressable';
import { input, inputContainer } from '../../electrons/input';
import { listBoxTrigger, listBoxTriggerIcon } from '../../electrons/list-box';

interface _ComboBoxProps<T extends object>
  extends Omit<ComboBoxProps<T>, 'children'>,
    VariantProps<typeof input>,
    FieldProps {
  children: ListBoxProps<T>['children'];
  name?: string;
  placeholder?: string;
  className?: string;
  inputSize?: number;
  inputContainerClasses?: string;
}

function _ComboBox<T extends object>({
  label,
  description,
  errorMessage,
  children,
  size,
  defaultSelectedKey,
  isInvalid,
  inputSize,
  placeholder = 'Select...',
  inputContainerClasses,
  ...props
}: _ComboBoxProps<T>) {
  return (
    <ComboBox
      defaultSelectedKey={defaultSelectedKey}
      isInvalid={errorMessage ? true : isInvalid}
      validationBehavior="aria"
      {...props}>
      {label && <FieldLabel>{label}</FieldLabel>}
      <Group
        className={twMerge(inputContainer(), inputContainerClasses)}
        data-invalid={errorMessage}>
        <Input
          className={twMerge(input({ size }), 'pr-0')}
          placeholder={placeholder}
          size={inputSize}
        />
        <Pressable className={listBoxTrigger()}>
          <Icon className={listBoxTriggerIcon()} name="chevron-down-large" />
        </Pressable>
      </Group>
      {errorMessage ? (
        <FieldError label={typeof label === 'string' ? label : ''}>{errorMessage}</FieldError>
      ) : (
        description && <FieldDescription>{description}</FieldDescription>
      )}
      <Popover className="w-[var(--trigger-width)]" isNonModal={false}>
        <ListBox>{children}</ListBox>
      </Popover>
    </ComboBox>
  );
}

export { _ComboBox as ComboBox };
export type { _ComboBoxProps as ComboboxProps };
